<template>
  <div
    v-loading="loading || initLoading"
    class="ritm-tree__wrapper"
  >
    <div class="ritm-tree__filters">
      <r-search-input
        :filter-text="filterText"
        :is-loading="filterLoading"
        @change="searchChange"
      />
      <r-text
        v-if="!noCounter"
        type="caption"
        class="ritm-tree__amount"
      >
        {{ amount }}
      </r-text>
      <r-button
        v-if="!noSort"
        mini
        :icon="asc ? 'sort-ascending' : 'sort-descending'"
        @click="toggleSort"
      />
    </div>
    <div
      v-if="tree.length || initLoading || loading"
      class="ritm-tree__list"
    >
      <tree-item
        v-for="item in tree"
        :key="item.id"
        :filter-text="filterText"
        :item="item"
        :show-tools="showTools"
        :edit-mode="editMode"
        :no-animations="noAnimations"
        :tool-click="toolClick"
        :no-tools="noTools"
        :node-click="nodeClick"
        :tool-kit="toolKit"
        :icon-by-item="iconByItem"
        :active-nodes="activeNodes"
        :loading-nodes="loadingNodes"
        :mini-tools="miniTools"
        :no-icon="noIcon"
        @updateNode="updateNode"
      />
    </div>
    <r-text
      v-else
      color-type="secondary"
      center
    >
      Источники данных отсутствуют
    </r-text>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { sort } from './sort-grouping'

export default {
  components: {
    treeItem: () => import('./tree-item')
  },
  props: {
    treeData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    showTools: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    miniTools: {
      type: Boolean,
      default: false
    },
    noTools: {
      type: Boolean,
      default: false
    },
    noCounter: {
      type: Boolean,
      default: false
    },
    noSort: {
      type: Boolean,
      default: false
    },
    noAnimations: {
      type: Boolean,
      default: false
    },
    toolClick: {
      type: Function,
      default: () => null
    },
    nodeClick: {
      type: Function,
      default: () => null
    },
    toolKit: {
      type: Function,
      default: () => []
    },
    iconByItem: {
      type: Function,
      default: () => 'folder-layer'
    },
    activeNodes: {
      type: Array,
      default: () => []
    },
    loadingNodes: {
      type: Array,
      default: () => []
    },
    noIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      asc: false,
      initLoading: false,
      filterText: '',
      filteredTree: null,
      filterLoading: false,
      debouncedFilter: null,
      amount: 0,
      tree: []
    }
  },
  computed: {
    sorted() {
      return sort(this.treeData, this.asc)
    }
  },
  watch: {
    sorted() {
      this.init()
    }
  },
  created() {
    this.init()
    this.debouncedFilter = debounce(this.init, 300)
  },
  methods: {
    updateNode(node) {
      this.$emit('updateNode', node)
    },
    init() {
      this.initLoading = true
      const tree = this.sorted
      const searchFields = ['name']
      const query = this.filterText?.trim()?.toLowerCase()

      if (!query) {
        if (!this.noCounter) {
          this.amount = tree.reduce((a, c) => {
            a += c.children ? c.children.length : 1
            return a
          }, 0)
        }
        this.filterLoading = false
        this.initLoading = false
        this.tree = tree
        return
      }

      if (!this.noCounter) {
        this.amount = 0
      }

      const filtered = tree.filter(e => {
        const compilation = searchFields.reduce(
          (acc, curr) => {
            const comp = e => {
              const value = e[curr]?.toLowerCase()
              const match = value?.includes(query)

              if (!e.children?.length) {
                if (match) {
                  acc.comp += value
                  acc.len += 1
                }
              } else {
                e.children.forEach(c => comp(c))
              }
            }

            comp(e)

            return acc
          },
          { len: 0, comp: '' }
        )
        if (!this.noCounter) {
          this.amount += compilation.len
        }
        const res = compilation.comp.includes(query)
        return res
      })
      this.filterLoading = false
      this.initLoading = false
      this.tree = filtered
    },
    toggleSort() {
      this.asc = !this.asc
      this.tree = sort(this.tree, this.asc)
    },
    searchChange(e) {
      this.filterLoading = !!e
      this.filterText = e
      this.debouncedFilter()
    }
  }
}
</script>

<style lang="scss" src="./ritm-tree.scss" />
