export function sort(tree, asc = false) {
  const conditions = [
    { field: 'name', type: 'text' },
    { field: 'datatype', type: 'compare', target: 'model' },
    { field: 'source_id', type: 'exist' }
  ]

  return tree.sort((a, b) => {
    if (a.children) sort(a.children)
    if (b.children) sort(b.children)

    const res = conditions.reduce((acc, { field, type, target }) => {
      switch (type) {
        case 'exist': {
          const res = a[field] && !b[field] ? 1 : !a[field] && b[field] ? -1 : 0
          if (res !== 0) return res
          break
        }
        case 'compare': {
          const res =
            a[field] !== target && b[field] === target
              ? 1
              : a[field] === target && b[field] !== target
                ? -1
                : 0
          if (res !== 0) return res
          break
        }
        case 'text': {
          const res = a?.[field]?.localeCompare(b?.[field], 'ru', {
            numeric: true
          })
          if (res !== 0) return asc ? res * -1 : res
          break
        }
      }
      return acc
    }, 0)
    return res
  })
}
